<template>
  <div class="login">
    <div class="login-box">
      <div class="white-l-box text-center">
        <img src="/assets/img/logo-big.png" alt="" />
        <h2 class="bold-19 text-gray mt-2 mb-4">Prodyflow</h2>
        <h2 class="bold-24 text-black mt-3">
          {{ $t("acceptLogin.acceptLogin") }}
        </h2>
        <div class="error my-2" v-if="error">{{ error }}</div>
        <div v-if="show">
          <div class="row text-start my-3">
            <div class="col-5">{{ $t("acceptLogin.platform") }}</div>
            <div class="col-7">{{ data.platform }}</div>
            <div class="col-5">{{ $t("acceptLogin.browser") }}</div>
            <div class="col-7">{{ data.browser }}</div>
            <div class="col-5" v-if="data.device">
              {{ $t("acceptLogin.device") }}
            </div>
            <div class="col-7" v-if="data.device">{{ data.device }}</div>
            <div class="col-5">{{ $t("acceptLogin.deviceType") }}</div>
            <div class="col-7">{{ data.device_type }}</div>
            <div class="col-5">{{ $t("acceptLogin.location") }}</div>
            <div class="col-7">
              {{ data.region }}, {{ data.city }} {{ data.country }}
            </div>
          </div>
          <button
            v-on:click="enable()"
            class="btn btn-type-2 w-100 text-center my-2"
          >
            <span class="bold-14">{{ $t("acceptLogin.acceptLogin") }}</span>
          </button>
          <button class="btn btn-outline-primary w-100 text-center mt-2 mb-4">
            <span class="bold-14">{{ $t("acceptLogin.deny") }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import tenant from "../../modules/tenant";

export default {
  name: "AcceptLogin",
  data() {
    return {
      error: null,
      show: false,
      hash: null,
      data: null,
      token: null,
    };
  },
  mounted() {
    const store = useStore();
    this.token = store.state.token;

    const route = useRoute();
    this.hash = route.params.hash;
    this.checkhash();
  },
  methods: {
    checkhash() {
      let data = new FormData();
      data.append("token", this.hash);

      fetch(tenant.getApiBaseUrl() + "/auth/get-qr", {
        method: "POST",
        body: data,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((res) => {
          if (!res.ok) {
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }
          return res.json();
        })
        .then((data) => {
          this.data = data;
          this.show = true;
        })
        .catch((err) => {
          err.json.then((data) => {
            this.error = data.message;
          });
        });
    },
    enable() {
      let data = new FormData();
      data.append("token", this.hash);

      fetch(tenant.getApiBaseUrl() + "/auth/accept-qr", {
        method: "POST",
        body: data,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((res) => {
          if (!res.ok) {
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }
          return res.json();
        })
        .then(() => {
          this.show = false;
          window.close();
        })
        .catch((err) => {
          err.json.then((data) => {
            this.error = data.message;
          });
        });
    },
  },
};
</script>
